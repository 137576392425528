// import "./src/scss/index.scss";
import "./src/styles/main.css";

export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== "production") {
    return null;
  }

  // because gatsby is essentially a single page app, we need to tell google analytics manually when the page changes
  const pagePath = location ? location.pathname + location.search + location.hash : undefined;
  setTimeout(() => {
    if (typeof gtag === "function") {
      gtag("event", "page_view", { page_path: pagePath });
    }
  }, 100);
};

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  const { pathname, hash } = location;
  const noScrollToTopRoutes = [`/`];
  if (noScrollToTopRoutes.indexOf(pathname) === -1) {
    window.scrollTo({ top: 0, behavior: 'instant' });
  } else {
    const [, top] = getSavedScrollPosition(location);
    window.scrollTo({ top, behavior: 'instant' });
  }
};